// A list of objects : {"question":"", "answer":""}
export const dataFAQ = [

 {
    "question":"How can I join your lab as a postdoc?",
    "answer": 'Email <a href="http://www.cs.toronto.edu/~florian/">me</a> with your CV, unofficial transcripts from your graduate and undergraduate studies, your research interests, and a link to your publications. Take a look at these <a href="https://www.sgs.utoronto.ca/awards-category/postdoctoral-awards/">postdoc funding opportunities</a>. If you are also interested in simultaneously collaborating with an industrial partner, then take a look at the <a href="https://www.mitacs.ca/en/programs/elevate#postdoc">Mitacs Elevate</a> and <a href="https://www.mitacs.ca/en/programs/accelerate">Mitacs Accelerate</a> program. There are also many <a href="https://brancoweissfellowship.org/">other</a> <a href="https://www.queensu.ca/postdoc/prospective-scholars/external-funding-sources-funding-and-fellowships"> external funding opportunities</a>. Whatever your funding situation may be, if I see that we are a strong research match, I will do my best to find funding for you.'
 },
    
 {
    "question": "How can I join your lab as a PhD student?",
    "answer": 'Feel free to email <a href="http://www.cs.toronto.edu/~florian/">me</a> with your CV, unofficial transcripts from your studies, and your research interests. If I see that we are a good research match I will reply and encourage you to submit an application. Unfortunately, I can not reply to all such emails because there are simply way too many. You should follow the application instructions for the PhD program at the <a href="https://web.cs.toronto.edu/graduate/msc-phd">Department of Computer Science at the University of Toronto</a>. You will be asked to list up to five potential supervisors when you apply. You should list my name, as well as robotics as one of your three research areas to ensure that I will see your application. Typically, the application deadline is in December for students applying to start in September of the following year. I will follow up with one or two Zoom interviews around end of December or January. If you get admitted to UofT CS you will have guaranteed funding for 4 years if you already have a MSc degree, or 5 years if you do not. That funding comes partially from the CS department, partially from my grants, and partially from any scholarships that you might receive.'
 },

{
    "question": "What types of scholarship opportunities are there for PhD students?",
    "answer": 'Canadian PhD applicants should definitely consider applying for the NSERC CGS-D award during the month of September, two months before they submit their application. In addition to that, there are many scholarship opportunities and entrance awards that are available for Canadian and international students. Take a look at this <a href="https://web.cs.toronto.edu/graduate/entrance-awards">list</a>.'
},
    
{
    "question": "How can I join your lab as a MSc student?",
    "answer": 'Follow the exact same process outlined above for PhD applicants. If you get admitted to UofT CS you will have guaranteed funding for 1.5 years. This is the case both for Canadian and for international students. That funding comes partially from the CS department, partially from my grants, and partially from any scholarships that you might receive.'
 },
{
    "question": "What types of scholarship opportunities are there for MSc students?",
    "answer": 'Canadian MSc applicants should definitely consider applying for the NSERC CGS-M award during the month of December, roughly the same time they submit their application. In addition to that, there are many scholarship opportunities and entrance awards that are available for Canadian students. Take a look at this <a href="https://web.cs.toronto.edu/graduate/entrance-awards">list</a>.'
 },
{
    "question": "Does getting a scholarship increase my chances of admission?",
    "answer": 'The scholarships that I mentioned above are awarded in late spring, after admission offers have gone out. So, receiving a scholarship is a bonus for your funding package after you get admitted, but it does not affect the admission decision.'
 },
{
    "question": "How can I maximize my chances of MSc/PhD admission?",
    "answer": 'The more of these criteria apply to you, the better: (1) you have done well in mathematically demanding courses, (2) you have done well in robotics, machine learning, or computer vision courses, (3) you have done research before, (4) you have strong software engineering skills, (5) you have read and understood relevant research papers, (6) you have industry experience, (7) you have excellent reference letters, (8) you are really persistent and eager to learn more about the field, (9) you can work well both independently and in a team setting.'
},

    {
    "question": "Is it ok if I email you to express interest in a MSc/PhD position in your lab?",
    "answer": "I welcome emails from prospective students, however, I might not be able to answer your email. I do reply to messages from prospective students who seem like a good fit for my lab and I encourage them to apply. If I haven't replied to your message, it's probably because I don't see a strong fit with my lab's research."
},

{
    "question": "What background reading should I do to understand your lab's research?",
    "answer": 'Check out this <a href="https://github.com/rvl-lab-utoronto/lab_onboarding_recommended_reading">recommended reading list</a> for new students who join my lab. It covers the set of all themes that current students in my lab are interested in and discuss daily. Note that you do not need to be interested in or understand all of these areas, but at least one.'
 },
    

{
    "question": "I am an undergraduate student at UofT. Can I do my thesis/capstone project with you?",
    "answer": 'I enjoy supervising about 3 undergraduate thesis/capstone students every year. You are expected to have taken or audited one of the <a href="http://www.cs.toronto.edu/~florian/">courses that I teach</a>, or some of the AI-related courses in CS or Engineering.'
},
    
{
    "question": "I am an undergraduate student at UofT. Can I do summer research in your lab?",
    "answer": 'Our lab offers multiple opportunities for undergraduate students to do paid summer research. You are expected to have taken or audited one of the <a href="http://www.cs.toronto.edu/~florian/">courses that I teach</a>, or some of the AI-related courses in CS or Engineering. If that is not the case, you are expected to have done well in math-heavy courses or have software engineering skills. If you are interested in applying for these positions, you should apply for NSERC USRA, UofT UTEA, Engineering ESROP awards, or Mitacs RTA awards. The deadlines for many of these awards are in mid-January, so please contact me in December. If you do not get any of these awards, I will try to find other ways to pay you, but I will have significantly fewer such positions every year.'
},
    
{
    "question": "I am a student at another university. Can I visit your lab or do research remotely?",
    "answer": 'If you think we are a strong research match and if you are planning to dedicate at least 4 months to your visit then feel free to email me with your CV, unofficial transcript, and research interests. You should also check out funding opportunities for visiting students, such as <a href="https://www.mitacs.ca/en/programs/globalink/globalink-research-award">Mitacs Globalink</a> or <a href="https://www.sgs.utoronto.ca/admissions/international-students/international-visiting-graduate-students/"> opportunities for international visiting graduate students at UofT</a>.'
 }    
    
]
