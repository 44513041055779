// A list of images
export const dataSlideshow = [
  "assets/slideshow/husky_utm_2020.jpg",
  "assets/slideshow/arm_farm.jpg",  
  "assets/slideshow/utah_flying_1.jpg",
  "assets/slideshow/boat_1.jpg",
  "assets/slideshow/boat_2.jpg",
  "assets/slideshow/visual_search_diver_tracking_iros_2020.jpg",
  "assets/slideshow/ball_catching_ke.gif"    
]
