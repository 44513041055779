// A list of objects : {"date":"", "content":""}
// Date is of format : year-month-day
export const dataNews = [

 {
    "date":"2023-09-1",
    "content":"<a href='https://arxiv.org/abs/2309.15770'>Our paper</a> on generating transferable adversarial scenarios for driving, using neural radiance fields, was accepted at CoRL. Congratulations to Yasasa and the co-authors."
 },
    
 {
    "date":"2023-06-1",
    "content":"Our paper on open-set 3D mapping, <a href='https://concept-fusion.github.io/'>ConceptFusion</a>, was accepted at RSS. Congratulations to Krishna Murthy and the wonderful co-authors, who also demoed the method live at CVPR."
 },
    
 {
    "date":"2023-04-1",
    "content":"The <a href='https://acceleration.utoronto.ca/'>Acceleration Consortium</a> won a <a href=''>$200M grant</a> to accelerate materials discovery using chemistry, machine learning, lab automation and robotics. <a href='https://acceleration.utoronto.ca/news/were-hiring'>We're hiring staff scientists, postdocs, graduate, and undergraduate students</a>."
 },
    
 {
    "date":"2023-02-1",
    "content":"Our field robotics paper on <a href='https://arxiv.org/abs/2209.11864'>vision-based navigation for autonomous boats</a> got accepted at ICRA. This is a collaboration with Tim Barfoot. Philip Huang, who led the work, graduated from his MSc and will start his PhD at CMU."
 },
    
  {
    "date":"2023-02-1",
    "content":"Two papers accepted at CVPR, one on <a href='https://arxiv.org/abs/2303.14595'>continual learning of neural networks</a>, led by Qiao Gu, and another on <a href='https://arxiv.org/abs/2303.13755'>sparsifying vision transformers</a>, led by Cong Wei."
  },
   
  {
    "date":"2023-02-1",
    "content":"Two papers on learning for task and motion planning got accepted at <a href='https://arxiv.org/abs/2210.14055'>ICRA</a> and <a href='https://arxiv.org/abs/2111.13144'>RA-L</a>. Mohamed Khodeir, the first author, graduated from his MSc."
  },

  {
    "date":"2023-02-1",
    "content":"Many thanks to <a href='https://scholar.google.com/citations?user=5HHtXzwAAAAJ'>Michal Zajac</a> and David Helm for visiting RVL for 4 and 6 months respectively. It was a pleasure hosting them."
  },
   
  {
    "date":"2022-03-1",
    "content":"Our paper on <a href='https://openaccess.thecvf.com/content/CVPR2022/html/Khorasgani_SLIC_Self-Supervised_Learning_With_Iterative_Clustering_for_Human_Action_Videos_CVPR_2022_paper.html'>video representation learning</a> was accepted to CVPR for oral presentation. "
  },

  {
    "date":"2022-02-1",
    "content":"Our paper on <a href='https://arxiv.org/abs/2110.07668'>equivariant representations for imitation learning</a> was accepted to ICRA. "
  },
    {
    "date":"2021-08-12",
    "content":"Two papers accepted at CoRL, one on <a href='https://openreview.net/forum?id=nWLt35BU1z_'>task planning in large 3D scene graphs</a>, and one on <a href='https://openreview.net/forum?id=tCfLLiP7vje'>perceiving transparent objects from RGBD sensors</a> (oral). "
  },
  {
    "date":"2021-07-07",
    "content":"Our paper on <a href='https://nv-tlabs.github.io/physics-pose-estimation-project-page/'>physics-based human motion tracking and synthesis from videos</a> was accepted at ICCV.  "
  },
  {
    "date":"2021-05-07",
    "content":"Florian received an <a href='https://www.amazon.science/research-awards/recipients?f0=2020&f1=00000173-2161-da60-a1f3-b9f59a740001&s=0'>Amazon Research Award</a>. "
  },
  {
    "date":"2021-02-28",
    "content":"Three papers accepted at ICRA, one on <a href='https://arxiv.org/abs/2009.11997'>continual model-based RL</a>, one on <a href='https://arxiv.org/abs/2005.10934'>reachability based exploration in RL</a>, and one on <a href='https://arxiv.org/abs/2011.01298'>handling imperfect demonstrations in imitation and RL</a>. "
  },
  {
    "date":"2021-02-28",
    "content":"One paper accepted at CVPR, <a href='https://arxiv.org/abs/2103.03891'>on latent space disentanglement in GANs for image synthesis</a>. "
  },
  {
    "date":"2021-01-12",
    "content":"Three papers accepted at ICLR, one on <a href='https://openreview.net/forum?id=iaO86DUuKi'>safe reinforcement learning</a>, one on <a href='https://openreview.net/forum?id=jXe91kq3jAq'>learning transferable skills for hierarchical planning</a>, and one on <a href='https://openreview.net/forum?id=c_E8kFWfhp0'>differentiable physics and rendering simulators</a>. "
  },
  {
    "date":"2020-12-2",
    "content":"Florian is co-organizing the NeurIPS 2020 <a href='https://montrealrobotics.ca/diffcvgp/'>workshop on differentiable computer vision, graphics, and physics</a>. "
  },
  {
    "date":"2020-12-2",
    "content":"Our <a href='https://arxiv.org/abs/2003.04514'>paper</a> on encouraging diversity in neural network ensembles was accepted at AAAI. "
  },
  {
    "date":"2020-07-20",
    "content":"<a href='https://www.youtube.com/watch?v=AeMSp-_hSnU'>Our work</a>, led by collaborators <a href='http://www.cim.mcgill.ca/~travism/'>Travis</a>, <a href='http://www.cim.mcgill.ca/~gamboa/'>Juan Camilo</a>, <a href='https://ca.linkedin.com/in/stefan-wapnick-00b122a6'>Stefan</a> and others, won the Best Paper Award in the RSS'20 Workshop on Self-Supervised Robot Learning."
  },
  {
    "date":"2020-07-2",
    "content":"Two papers accepted at IROS. One on <a href='https://arxiv.org/abs/2003.10010'>visual search</a> and one on <a href='https://arxiv.org/abs/2003.07489'>mobile manipulation</a>.   "
  },
  {
    "date":"2020-06-05",
    "content":"Florian co-organized the <a href='https://starslab.ca/workshops/icra_2020_debates/'>Debates on the Future of Robotics Research</a> workshop at ICRA 2020."
  },
  {
    "date":"2020-05-07",
    "content":"<a href='http://www.roboticsproceedings.org/rss16/p048.html'>Our paper on vision-based navigation</a> was accepted at RSS. "
  },  
]
